import { Link, type LinkProps } from "@remix-run/react";
import type { ReactNode } from "react";
import { cx } from "~/design/generated/css";
import { button } from "~/design/generated/recipes";
import type { ButtonVariantProps } from "~/design/generated/recipes";
import { ButtonSlot, type ButtonSlotProps } from "./ButtonSlot";

export type ButtonProps = ButtonVariantProps &
  LinkProps & {
    children?: ReactNode;
    startSlot?: ButtonSlotProps["slot"];
    endSlot?: ButtonSlotProps["slot"];
    startSlotAlign?: "center" | "side";
    endSlotAlign?: "center" | "side";
  };

export function ButtonLink({
  color,
  variant,
  size,
  roundness,
  align,
  children,
  full,
  backdropBlur,
  className,
  startSlot,
  endSlot,
  iconOnly,
  startSlotAlign = "center",
  endSlotAlign = "center",
  ...props
}: ButtonProps) {
  const buttonClasses = button({
    color,
    variant,
    size,
    roundness,
    align,
    full,
    startSlotAlign,
    endSlotAlign,
    backdropBlur,
    iconOnly,
    hasStartSlot: Boolean(startSlot),
    hasEndSlot: Boolean(endSlot),
  });

  return (
    <Link {...props} className={cx(buttonClasses.root, className)}>
      <>
        <div className={buttonClasses.startSlot}>
          <ButtonSlot slot={startSlot} size={size} />
        </div>
        {children}
        <div className={buttonClasses.endSlot}>
          <ButtonSlot slot={endSlot} size={size} />
        </div>
      </>
    </Link>
  );
}
